import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import ListaZabiegowKarta from "./ListaZabiegowKarta";

const Lista = styled.ul`
  background-color: #fff0ee;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 250px;
  margin-right: 250px;
  @media screen and (max-width: 1600px) {
    margin-left: 150px;
    margin-right: 150px;
  }
`;

const Wrapper = styled.article`
  background-color: #fff0ee;
  width: 100%;
`;

const ListaZabiegow = (props: any) => {
  return (
    <Wrapper>
      <Lista className={props.className || ""} id={"zabiegi"}>
        {props.karty.map((karta: any) => (
          <ListaZabiegowKarta key={karta.id} {...karta} />
        ))}
      </Lista>
    </Wrapper>
  );
};

export default ListaZabiegow;
