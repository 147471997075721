import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import KartaRichText from "../components/KartaRichText";
import ListaZabiegow from "../components/ListaZabiegow";
import SEO from "../components/SEO";
import BanerPageLayout from "../layout/BanerPageLayout";
import { FontSizeHeader, Label } from "../styles/shared";

const SzerokaListaZabiegow = styled(ListaZabiegow)`
  margin-left: 150px;
  margin-right: 150px;
`;

const ZobaczRowniez = styled.h2`
  ${Label}
  ${FontSizeHeader}
  height: fit-content;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  background-color: #fff0ee;
`;

const TrescMiedzyKartami = styled.h2`
  ${Label}
  ${FontSizeHeader}
  height: fit-content;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  width: 100%;
`;

export default ({
  data: { contentfulStronaZabiegu, allContentfulListaZabiegow },
}: any) => {
  const pozostaleZabiegi = allContentfulListaZabiegow.nodes[0].karty.filter(
    (karta: any) => karta.stronaZabiegu.slug !== contentfulStronaZabiegu.slug
  );
  return (
    <BanerPageLayout
      baner={contentfulStronaZabiegu.baner}
      extraContent={
        <React.Fragment>
          <ZobaczRowniez>Zobacz również</ZobaczRowniez>
          <SzerokaListaZabiegow karty={pozostaleZabiegi} />
        </React.Fragment>
      }
    >
      <SEO {...contentfulStronaZabiegu} />
      {contentfulStronaZabiegu.tresc.map((tresc: any, idx: number) => (
        <nav key={tresc.id}>
          {idx !== 0 && tresc.tytuSekcji ? (
            <TrescMiedzyKartami>{tresc.tytuSekcji}</TrescMiedzyKartami>
          ) : null}
          <KartaRichText
            tytul={idx === 0 ? tresc.tytuSekcji : null}
            content={tresc.tresc}
          />
        </nav>
      ))}
    </BanerPageLayout>
  );
};

export const data = graphql`
  query ($id: String) {
    contentfulStronaZabiegu(id: { eq: $id }) {
      seoOpisKarty
      seoTytulKarty
      slug
      baner {
        gatsbyImageData
        description
        contentful_id
        __typename
      }
      tresc {
        id
        tytuSekcji
        tresc {
          raw
          references {
            gatsbyImageData
            description
            contentful_id
            __typename
          }
        }
      }
    }
    allContentfulListaZabiegow {
      nodes {
        karty {
          id
          nazwaZabiegu
          krotkiOpis {
            krotkiOpis
          }
          miniaturka {
            gatsbyImageData
            description
            contentful_id
            __typename
          }
          stronaZabiegu {
            slug
          }
        }
      }
    }
  }
`;
