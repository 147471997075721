import * as React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { Description, Label } from "../styles/shared";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Container = styled.li`
  border-radius: 17px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  margin: 1em;

  flex: 1;
  min-width: 300px;
  max-width: 300px;
`;

const shared = css`
  margin: 0.5em;
  text-align: center;
`;
const Header = styled.h2`
  ${shared}
  ${Label}
  height: 10%;
`;
const ZabiegDescription = styled.p`
  ${shared}
  ${Description}
`;
const More = styled(Link)`
  ${shared}
  ${Label}
  text-decoration: underline;
  margin-top: auto;
`;
const Img = styled.div`
  width: 250px;
  height: 250px;
  @media screen and (max-width: 768px) {
    height: 150px;
    width: 150px;
  }
`;

interface Zabieg {
  nazwaZabiegu: string;
  krotkiOpis: { krotkiOpis: string };
  miniaturka: {
    gatsbyImageData: string | any;
    description: string;
    contentful_id: string;
    __typename: string;
  };
  stronaZabiegu: {
    slug: string;
  };
}

export default (props: Zabieg) => {
  const image = getImage(props.miniaturka as any);
  return (
    <Container>
      {image && (
        <Img>
          <GatsbyImage image={image} alt={props.miniaturka.description} />
        </Img>
      )}
      <Header>{props.nazwaZabiegu}</Header>
      <ZabiegDescription>{props.krotkiOpis.krotkiOpis}</ZabiegDescription>
      <More to={"/zabiegi/" + props.stronaZabiegu.slug}>
        Dowiedz się więcej
      </More>
    </Container>
  );
};
